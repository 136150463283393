export const theme = {
    colors: {
        'primary': '#777',
        'secondary': '#000',
    },
    space: [16, 24, 52],
    fontSizes: {
        h1: '52px',
        h2: '44px',
        h3: '36px',
        text: '24px',
        monospace: '20px'
    },
    fonts: {
        header: 'Avenir-lt, "Helvetica Neue", Helvetica, Arial, sans-serif',
        text: 'Avenir-lt, "Helvetica Neue", Helvetica, Arial, sans-serif',
        monospace: 'Avenir-lt, Consolas", "Menlo", monospace'
    },
};

export const transition = {
    from: {
        opacity: 0,
    },
    enter: {
        opacity: 1,
    },
    leave: {
        opacity: 0,
    }
};