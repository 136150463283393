import { useEffect, useState } from "react"
import { useParams } from "react-router"
import EditorForm from "../../components/forms/ArticleEditorForm"
import { callApi } from "../../components/Request"

function Editor() {
    const articleId = useParams()['id']
    const url = articleId ? '/articles/' + articleId : null
    const [previousUploads, setPreviousUploads] = useState([])

    // structure of article Entity
    const [data, setData] = useState({
        articleTitle: "",
        articleDescription: "",
        articlePublished: false,
        Tags: []
    })
    const [content, setContent] = useState("")
    const [loaded, setLoaded] = useState(false)

    const prevUploads = (res) => {
        const prevUploads = []
        res?.Uploads.forEach(upload => {
            prevUploads.push(upload.id)
        });
        return prevUploads
    }

    useEffect(() => {
        if (articleId) {
            // if article exist load data
            callApi('get', url).then(res => {
                // if an article is load, set them in data state
                // manage content out of the article object
                // MDeditor need raw value for content
                const article = { ...res }
                delete article.articleContent
                setData(article)
                setContent(res.articleContent)
                setPreviousUploads(prevUploads(res))
                setLoaded(true)
            })
        }
        else {
            //if new
            setLoaded(true)
        }
    }, [])

    return (
        <main className="flex-shrink-0">
            {loaded && <EditorForm initialArticle={data} initialContent={content} previousUploads={previousUploads} />}
        </main>
    )

}

export default Editor