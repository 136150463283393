import { useEffect, useLayoutEffect, useState } from "react";
import { Table, Form } from "react-bootstrap";
import { callApi } from "../../components/Request";
import Select from 'react-select'
import { customStyles } from '../../components/styles/ReactMenuCustomStyles'

export default function AdminUsers({ superadmin }) {
    const [users, setUsers] = useState([])
    const url = "/users"

    useEffect(() => {
        callApi('get', url).then(res => {
            setUsers(res)
        })
    }, [])

    if (!superadmin) {
        return (<p>Access denied</p>)
    }

    return (
        <Table className="mt-2" striped bordered>
            <thead>
                <tr>
                    <th>#</th>
                    <th>Email</th>
                    <th>Alias</th>
                    <th>Role</th>
                    <th>Active</th>
                </tr>
            </thead>
            <tbody>
                {
                    users.map((user) => (
                        <UserElement key={user.id} user={user} />
                    ))
                }
            </tbody>
        </Table>
    );
}

function UserElement({ user }) {
    const [userData, setUserData] = useState(user)

    const handleChange = (e) => {
        const changeRole = window.confirm(`Voulez vous vraiment donner le role ${e.label} à l'utilisateur ${user.userEmail} ?`)
        const url = '/users/' + user.id
        if (changeRole === true) {
            setUserData({ ...userData, userRole: e.value })
            callApi('put', url, { userRole: e.value }).then((res) => {
                //set Alert Here
            })
        }
    }

    const handleActiveChange = (e) => {
        const activeUser = window.confirm(`Voulez vous vraiment ${userData.userEnabled ? "désactiver" : "activer"} le compte associé à ${userData.userEmail} ?`)
        const url = '/users/' + user.id
        if (activeUser === true) {
            setUserData({ ...userData, userEnabled: e.target.checked })
            callApi('put', url, { userEnabled: e.target.checked })
        }
    }

    return (
        <tr className="align-middle">
            <td>{user.id}</td>
            <td>{user.userEmail}</td>
            <td>{user.userAlias}</td>
            <td>
                <Select
                    options={[
                        {
                            value: null,
                            label: 'user'
                        },
                        {
                            value: 'admin',
                            label: 'admin'
                        },
                        {
                            value: 'superadmin',
                            label: 'superadmin'
                        }
                    ]}
                    styles={customStyles}
                    value={
                        userData.userRole
                            ? { value: userData.userRole, label: userData.userRole }
                            : { value: null, label: 'user' }
                    }
                    onChange={handleChange}
                />
            </td>
            <td>
                <Form>
                    <Form.Check
                        type="switch"
                        checked={userData.userEnabled}
                        onChange={handleActiveChange}
                    />
                </Form>
            </td>
        </tr>


    )
}