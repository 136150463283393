import { useState } from "react";
import { Modal, Button, Row, Col, Form } from "react-bootstrap"
import { callApi } from "../Request";
import { ChromePicker } from 'react-color';
import { BsCircleFill } from 'react-icons/bs'

export default function TagsEditor({ category, setCategoryState, symbol = null }) {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [categoryData, setCategoryData] = useState(category ? category : {
        categoryName: "",
        categoryColor: ""
    })

    const handleChange = (e) => {
        e.preventDefault()
        setCategoryData({ ...categoryData, [e.target.name]: e.target.value })
    }

    const handleChangeColor = (color) => {
        setCategoryData({ ...categoryData, categoryColor: color.hex })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (categoryData.categoryName) {
            if (categoryData.id) {
                const postUrl = '/categories/' + category.id
                callApi('put', postUrl, categoryData).then(() => {
                    setCategoryState(categoryData)
                    handleClose()
                })
            }
            else {
                const postUrl = '/categories'
                callApi('post', postUrl, categoryData).then(() => {
                    setCategoryState(categoryData)
                    handleClose()
                })
            }
        }
    }

    return (
        <>
            <Button variant="primary" onClick={handleShow} className={symbol ? "mb-4" : ""}>
                {symbol ? symbol : 'Edit'}
            </Button>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <Row>
                            <Col>
                                <BsCircleFill color={categoryData.categoryColor} />
                            </Col>
                            <Col>
                                {categoryData.categoryName}
                            </Col>
                        </Row>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <Form.Label>Category name</Form.Label>
                            <Form.Control onChange={handleChange} name="categoryName" value={categoryData.categoryName} type="text" />
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col className="d-flex justify-content-center">
                            <ChromePicker
                                disableAlpha color={categoryData.categoryColor}
                                onChangeComplete={handleChangeColor}
                                style={{ background: '#333' }}
                            />
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleSubmit}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}