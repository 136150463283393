function NoMatch() {

    return (
        < main className="flex-shrink-0" >
			<div className="container pt-5">
                Oh no ! 404 !
            </div>
        </main>
    )
}

export default NoMatch