import { useParams } from 'react-router'
import { useState, useEffect } from 'react'
import { callApi } from '../../components/Request'
import { theme, transition } from '../../components/styles/SlidesStyle';

import {
    Box,
    Deck,
    FlexBox,
    MarkdownSlideSet
} from 'spectacle';

function Slides() {
    const post = useParams()
    const url = '/articles/' + post.id
    const [data, setData] = useState()

    useEffect(() => {
        callApi('get', url).then((res) => {
            setData(res)
        })
    }, [])

    const template = () => (
        <FlexBox
            justifyContent="space-between"
            position="absolute"
            bottom={0}
            width={1}
            color='#333'
        >
            <Box padding="0 1em" color="#333">
                &copy; LessonSharing {new Date().getFullYear()} - All rights reserved
            </Box>
            {/*<Box paddingRight="1em">
                <Progress color="#fff" />
                <FullScreen color="#333" />
            </Box>*/}
        </FlexBox >
    );

    if (!data) {
        return ('Loading')
    }

    return (
        <Deck theme={theme} template={template} transition={transition}>
            <MarkdownSlideSet className="lsSlides" backgroundColor="#fff">
                {data.articleContent}
            </MarkdownSlideSet>
        </Deck>
    )
}

export default Slides