import { useNavigate, useParams } from 'react-router'
import { useState, useEffect } from 'react'
import { callApi } from '../../components/Request';
import Paginate from '../../components/Paginate';
import { Link } from 'react-router-dom';
import { Container, Table, Dropdown, Button } from 'react-bootstrap';
import SearchbarForm from '../../components/forms/SearchbarForm';
import { AiOutlinePlusCircle } from 'react-icons/ai'

function AdminArticles() {
    const url = '/articles/all'
    const [data, setData] = useState()
    const [filter, setFilter] = useState({
        text: "",
        categoryId: null,
        tags: [],
        tagsIds: [],
        page: 0
    })
    const redirect = useNavigate()

    useEffect(() => {
        callApi('post', url, filter)
            .then(res => {
                setData(res)
            })
    }, [filter])

    const handleChangePage = (page) => {
        setFilter({ ...filter, page: page })
    }

    if (!data) {
        return (
            <div className="container">
                <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        )
    }
    return (
        <Container fluid="true">
            <SearchbarForm filter={filter} setFilter={setFilter} />
            <Button onClick={() => { redirect('/admin/editor') }} variant="primary" className="mb-4"><AiOutlinePlusCircle size={25} /></Button>
            <Table striped bordered>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Title</th>
                        <th>Author</th>
                        <th>Is Published</th>
                        <th>Toolbar</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        data.Articles.map((article) => (
                            <ArticleRow key={article.id} filter={filter} setFilter={setFilter} article={article} />
                        ))
                    }
                </tbody>
            </Table>
            <div className="row mt-4 mb-5">
                <div className="col d-flex justify-content-center sm">
                    <Paginate setPage={handleChangePage} previousPage={data.previousPage} currentPage={data.currentPage} nextPage={data.nextPage} totalPages={data.totalPages} />
                </div>
            </div>
        </Container>

    )
}

function ArticleRow({ article, setFilter, filter }) {
    const url = '/articles/' + article.id

    const handleClickDelete = () => {
        const del = window.confirm(`Voulez vous vraiment supprimer l'article ${article.articleTitle} ?`)
        if (del === true) {
            callApi('DELETE', url).then((res) => {
                setFilter({ ...filter })
            })
        }
    }

    const hidemenu = function () {
        const nav = document.getElementById("MainNav")
        nav.style.opacity = 0;
    }

    return (
        <tr>
            <td>{article.id}</td>
            <td>{article.articleTitle}</td>
            <td>{article.User && article.User.userEmail}</td>
            <td>{article.articlePublished.toString()}</td>
            <td>
                <Dropdown>
                    <Dropdown.Toggle variant="outline-success" id="dropdown-basic">
                        Options
                    </Dropdown.Toggle>

                    <Dropdown.Menu variant="dark">
                        <Dropdown.Item as={Link} to={`/article/${article.id}`}> View </Dropdown.Item>
                        <Dropdown.Item as={Link} onClick={hidemenu} to={`/admin/slides/${article.id}`}> Slides </Dropdown.Item>
                        <Dropdown.Item as={Link} onClick={hidemenu} to={`/admin/slides/${article.id}?presenterMode=true`}> Presenter Mode </Dropdown.Item>
                        <Dropdown.Item as={Link} to={`/admin/editor/${article.id}`}> Edit </Dropdown.Item>
                        <Dropdown.Item><span onClick={handleClickDelete} className="btn btn-sm btn-outline-danger">Delete</span></Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </td>
        </tr>
    )
}


export default AdminArticles