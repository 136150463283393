import React, { useState, useEffect } from 'react';
import { AuthContext } from './contexts/AuthContext'
import MainRouter from './routers/MainRouter'
import { callApi } from './components/Request';
import Navbar from './components/navbars/MainNavbar';
import Login from './pages/user/Login'
import { Container } from 'react-bootstrap';


function App() {
    const [isAuthenticated, userHasAuthenticated] = useState();
    const [expandedNavbar, setExpandedNavbar] = useState(false)

    useEffect(() => {
        if (localStorage.getItem("token")) {
            const url = "/auth/verify"
            callApi('get', url).then((res) => {
                if (res.valid) {
                    userHasAuthenticated(true)
                }
            })
        }
    }, [])

    const handleClickOutside = () => {
        if (expandedNavbar) {
            setExpandedNavbar(false)
        }
    }

    if (isAuthenticated) {
        return (
            <AuthContext.Provider value={{ isAuthenticated, userHasAuthenticated }}>
                <Navbar expandedNavbar={expandedNavbar} setExpandedNavbar={setExpandedNavbar} />
                <Container onClick={handleClickOutside} id="content">
                    <MainRouter />
                </Container>
            </AuthContext.Provider>
        )
    }
    return (
        <AuthContext.Provider value={{ isAuthenticated, userHasAuthenticated }}>
            <Login />
        </AuthContext.Provider>
    )

}

export default App