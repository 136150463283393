import React, { useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router';
import AdminArticles from '../pages/admin/AdminArticles';
import AdminCategories from '../pages/admin/AdminCategories';
import Editor from '../pages/admin/AdminArticleEditor';
import Tags from '../pages/admin/AdminTags';
import Slides from '../pages/user/Slides'
import { callApi } from '../components/Request';
import { Container } from 'react-bootstrap';
import { useAuthContext } from '../contexts/AuthContext';
import AdminDashboard from '../pages/admin/AdminDashboard';
import AdminUsers from '../pages/admin/AdminUsers';

export default function AdminRouter() {
    const [authorize, setAuthorize] = useState(false)
    const [superAdmin, setSuperAdmin] = useState(false)
    const { userHasAuthenticated } = useAuthContext();
    const redirect = useNavigate()

    useEffect(() => {
        if (localStorage.getItem("token")) {
            const url = "/auth/get-role"
            callApi('get', url).then((res) => {
                if (res.role === 'admin' || res.role === 'superadmin') {
                    setAuthorize(true)
                    if (res.role === 'superadmin') {
                        setSuperAdmin(true)
                    }
                }
                else {
                    handleLogout()
                }
            })
        }
    }, [])

    const handleLogout = () => {
        localStorage.clear()
        userHasAuthenticated(false);
        redirect('/')
    }

    if (!authorize) {
        <p>Access denied</p>
    }

    return (
        <Container fluid="true" className="mt-3">
            <Routes>
                <Route index element={<AdminDashboard />} />
                <Route path="articles" element={<AdminArticles />} >
                    <Route path=":page" element={<AdminArticles />} />
                </Route>
                <Route path="editor" element={<Editor />}>
                    <Route path=":id" element={<Editor />} />
                </Route>
                <Route path="tags" element={<Tags />} />
                <Route path="categories" element={<AdminCategories />} />
                <Route path="slides/:id" element={<Slides />} />
                <Route path="users" element={<AdminUsers superadmin={superAdmin} />} />
            </Routes>
        </Container>
    );
}