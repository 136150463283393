import { Link } from 'react-router-dom';
import { callApi } from '../../components/Request';
import { useState, useEffect } from 'react'
import Paginate from '../../components/Paginate';
import { useParams, useNavigate } from 'react-router-dom'
import { Card, Container } from "react-bootstrap"
import SearchbarForm from '../../components/forms/SearchbarForm';
import Badge from '../../components/Badge'

function Articles() {
    const url = '/articles/published'
    const [data, setData] = useState()
    const [filter, setFilter] = useState({
        text: "",
        categoryId: null,
        tags: [],
        tagsIds: [],
        page: 0
    })

    useEffect(() => {
        callApi('post', url, filter)
            .then(res => {
                setData(res)
            })
    }, [filter])

    const handleChangePage = (page) => {
        setFilter({ ...filter, page: page })
    }

    if (!data) {
        return (
            <div className="container">
                <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        )
    }

    return (
        <main className="flex-shrink-0">
            <section className="py-5 text-center container">
                <div className="row py-lg-5">
                    <div className="col-lg-6 col-md-8 mx-auto">
                        <h1 className="fw-light">Cours</h1>
                        <p className="lead text-muted">Ici, vous pouvez consulter les cours, c'est génial.</p>
                    </div>
                </div>
            </section>
            <SearchbarForm filter={filter} setFilter={setFilter} />
            <div className="container pt-3">
                <div className="row">
                    {
                        data.Articles.map((article) => (
                            <ArticleCard setFilter={setFilter} filter={filter} key={article.id} article={article} />
                        ))
                    }
                </div>
                <div className="row mt-4 mb-5">
                    <div className="col d-flex justify-content-center">
                        <Paginate setPage={handleChangePage} previousPage={data.previousPage} currentPage={data.currentPage} nextPage={data.nextPage} totalPages={data.totalPages} />
                    </div>
                </div>
            </div>
        </main>
    )
}

function ArticleCard({ article, setFilter, filter }) {
    return (
        <Container className="col-md-6 col-sm-12 artCard">
            <Card className="card">
                <Card.Header>{article.articleTitle}</Card.Header>
                <Card.Body>
                    <Card.Text>{article.articleDescription}</Card.Text>
                    <Link to={`/article/${article.id}`}><span className="btn btn-outline-primary">Acceder à l'article</span></Link>
                </Card.Body>
                <Card.Footer className="d-flex gap-2">
                    {
                        article.Tags.map((tag) => {
                            return (<div onClick={() => { setFilter({ ...filter, page: 0, tagsIds: [tag.id], tags: [{ ...tag, label: tag.tagName, value: tag.id }] }) }} style={{ cursor: 'pointer' }} key={tag.id}><Badge color={tag.tagColor} name={tag.tagName} /></div>)
                        })
                    }
                </Card.Footer>
            </Card>
        </Container >
    )
}

export default Articles