import React, { useEffect, useState } from 'react';
import { Form, Container, Row, Col } from "react-bootstrap"
import Select from 'react-select'
import { callApi } from '../Request';
import { customStyles } from '../styles/ReactMenuCustomStyles'


function SearchbarForm({ filter, setFilter }) {
    const [categoryList, setCategoryList] = useState([{ value: null, label: 'all' }])
    const [tagList, setTagList] = useState([])

    useEffect(() => {
        callApi('get', '/categories').then((res) => {
            res.map((category) => {
                setCategoryList(prevState => [...prevState, { ...category, value: category.id, label: category.categoryName }])
            })
        })
        callApi('get', '/tags').then((res) => {
            res.map((tag) => {
                setTagList(prevState => [...prevState, { ...tag, value: tag.id, label: tag.tagName }])
            })
        })
    }, [])

    const handleChangeText = (e) => {
        setFilter({ ...filter, text: e.target.value, page: 0 })
    }

    const handleChangeCategory = (e) => {
        setFilter({ ...filter, categoryId: e.value, page: 0 })
    }

    const handleChangeTags = (e) => {
        setFilter({ ...filter, tags: e, tagsIds: e.map(t => t.id), page: 0 })
    }

    return (
        <Form>
            <Row>
                <Col className="col-12 col-lg-8 mb-3">
                    <Form.Text>Recherche :</Form.Text>
                    <Form.Control type="searchText" onChange={handleChangeText} placeholder="Tapez votre recherche ici" />
                </Col>
                <Col className="col-12 col-lg-4 mb-3">
                    <Form.Text>Filtre categories</Form.Text>
                    <Select
                        styles={customStyles}
                        options={categoryList}
                        onChange={handleChangeCategory}
                    />
                </Col>
            </Row>
            <Row>
                <Form.Text>Filtre tags</Form.Text>
                <Select
                    options={tagList}
                    styles={customStyles}
                    onChange={handleChangeTags}
                    className="mb-3"
                    value={filter.tags}
                    isMulti
                />
            </Row>
        </Form>

    )
}

export default SearchbarForm;

