import React from 'react';
import { callApi } from '../../components/Request';
import { useState, useEffect } from 'react'
import { Button, Table } from "react-bootstrap"
import CategoryEditorForm from "../../components/forms/CategoryEditorForm"
import { AiOutlinePlusCircle } from 'react-icons/ai'
import { RiDeleteBin5Line } from 'react-icons/ri'
import { BsCircleFill } from 'react-icons/bs'

function AdminCategories() {
    const url = '/categories/'
    const [categories, setCategories] = useState()
    const [newCategory, setNewCategory] = useState()

    useEffect(() => {
        callApi('get', url).then(res => {
            setCategories(res)
        })
    }, [newCategory])

    if (!categories) {
        return (
            <div className="container">
                <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        )
    }
    return (
        <>
            <CategoryEditorForm setCategoryState={setNewCategory} symbol={<AiOutlinePlusCircle size={25} />} />
            <Table className="mt-2" striped bordered>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Category</th>
                        <th>ToolBar</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        categories.map((category) => (
                            <CategoryElement key={category.id} category={category} triggerRefresh={setNewCategory} />
                        ))
                    }
                </tbody>
            </Table>
        </>
    )
}

function CategoryElement({ category, triggerRefresh }) {
    const [categoryState, setCategoryState] = useState(category)
    const url = '/categories/' + category.id

    const handleClickDelete = () => {
        const del = window.confirm(`Voulez vous vraiment supprimer la catégorie ${category.categoryName} ?`)
        if (del === true) {
            callApi('DELETE', url).then(() => {
                triggerRefresh(category.id)
            })
        }
    }

    return (
        <tr>
            <td>{categoryState.id}</td>
            <td><BsCircleFill color={categoryState.categoryColor} /> {categoryState.categoryName}</td>
            <td className='d-flex gap-2'>
                <CategoryEditorForm category={categoryState} setCategoryState={setCategoryState} />
                <Button variant='danger' onClick={handleClickDelete}>
                    <RiDeleteBin5Line />
                </Button>
            </td>
        </tr>


    )
}

export default AdminCategories;
