import { Routes, Route } from 'react-router-dom'
import Articles from '../pages/user/Articles'
import Article from '../pages/user/Article'
import Home from '../pages/user/Home'
import NoMatch from '../pages/user/Nomatch'
import AdminRouter from '../routers/AdminRouter'

export default function MainRouter() {
    return (
        <Routes>
            <Route path="/">
                <Route index element={<Home />} />
                <Route path="articles" element={<Articles />}>
                    <Route path=":page" element={<Articles />} />
                </Route>
                <Route path="article/:id" element={<Article />} />
                <Route path="admin/*" element={<AdminRouter />} />
            </Route>
            <Route path="*" element={<NoMatch />} />
        </Routes>
    )
}