import { useState } from 'react';
import BootstrapAlert from 'react-bootstrap/Alert'

export default function Alert({ initalAlert }) {
    const [show, setShow] = useState(true);
    const [alert] = useState(initalAlert)
    if (show) {
        return (
            <BootstrapAlert
                onClose={() => {
                    setShow(false)
                }}
                style={{
                    position: "fixed",
                    marginTop: "20px",
                    right: "20px",
                    zIndex: "1"
                }}
                variant={alert.variant}
                dismissible
            >
                {alert.message}
            </BootstrapAlert >
        )
    }
    return null
}
