import { useState } from 'react'
import { useEffect } from 'react'
import { callApi } from '../../components/Request'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { Row, Col, Container } from 'react-bootstrap'
ChartJS.register(ArcElement, Tooltip, Legend);

function Main() {
    const [chartConfig, setChartConfig] = useState({
        type: 'doughnut',
        data: null
    })
    const [globalStats, setGlobalStats] = useState()
    const countersElements = document.getElementsByClassName("counter")

    useEffect(() => {
        callApi('get', '/stats/chart').then(res => setChartConfig({ ...res, data: res }))
        callApi('get', '/stats/globals').then(res => {
            setGlobalStats(res)
        })
    }, [])

    const style = {
        fontSize: '35px',
        marginRight: '10px'
    }

    return (
        < main className="flex-shrink-0" >
            <div className="container pt-5">
                <section className="py-5 text-center container">
                    <div className="row py-lg-5">
                        <h1 className="fw-light">Learn</h1>
                        <Col xs={12}>
                            <Row className="pt-5">
                                <Col xs={12} lg={4} >
                                    <h3><b style={style} className="counter">{globalStats && globalStats.users}</b> Utilisateurs</h3>
                                </Col>
                                <Col xs={12} lg={4} >
                                    <h3><b style={style} className="counter">{globalStats && globalStats.articles}</b> Cours</h3>
                                </Col>
                                <Col xs={12} lg={4}>
                                    <h3><b style={style} className="counter">{globalStats && globalStats.categories}</b> Catégories</h3>
                                </Col>
                            </Row>
                        </Col>
                    </div>
                </section>
                <Row>
                    <Col xs={12} lg={4}>
                        {chartConfig.data && <Doughnut data={chartConfig} />}
                    </Col>
                </Row>
            </div>
        </main>
    )
}


export default Main