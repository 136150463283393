import { useState } from "react";
import { Modal, Button, Row, Col, Form } from "react-bootstrap"
import Badge from "../Badge"
import { ChromePicker } from 'react-color';
import { callApi } from "../Request";

export default function TagsEditor({ tag, setTagState, symbol = null }) {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [tagData, setTagData] = useState(tag ? tag : {
        tagName: "",
        tagColor: ""
    })

    const handleChange = (e) => {
        e.preventDefault()
        setTagData({ ...tagData, [e.target.name]: e.target.value })
    }

    const handleChangeColor = (color) => {
        setTagData({ ...tagData, tagColor: color.hex })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (tagData.tagName && tagData.tagColor) {
            if (tagData.id) {
                const postUrl = '/tags/' + tag.id
                callApi('put', postUrl, tagData).then(() => {
                    setTagState(tagData)
                    handleClose()
                })
            }
            else {
                const postUrl = '/tags'
                callApi('post', postUrl, tagData).then(() => {
                    setTagState(tagData)
                    handleClose()
                })
            }
        }
    }

    return (
        <>
            <Button variant="primary" onClick={handleShow} className={symbol ? "mb-4" : ""}>
                {symbol ? symbol : 'Edit'}
            </Button>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{tagData.tagName}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xs="auto">
                            <p>Preview : </p>
                        </Col>
                        <Col>
                            <Badge color={tagData.tagColor} name={tagData.tagName} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Label>Tag name</Form.Label>
                            <Form.Control onChange={handleChange} name="tagName" value={tagData.tagName} type="text" />
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col className="d-flex justify-content-center">
                            <ChromePicker
                                disableAlpha color={tagData.tagColor}
                                onChangeComplete={handleChangeColor}
                                style={{ background: '#333' }}
                            />
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleSubmit}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}