import Pagination from 'react-bootstrap/Pagination'
//import { useNavigate } from 'react-router-dom';

export default function Paginate({ previousPage, currentPage, nextPage, totalPages, setPage }) {
    if (totalPages === 1) {
        return null
    }
    return (
        <Pagination>
            {(previousPage && currentPage > 1) ? <Pagination.First onClick={() => setPage(0)} /> : ""}
            {previousPage !== null ? <Pagination.Prev onClick={() => setPage(previousPage)} /> : ""}
            {currentPage >= 1 ? <Pagination.Item onClick={() => setPage(0)}>{1}</Pagination.Item> : ""}
            {currentPage > 2 ? <Pagination.Ellipsis /> : ""}
            {previousPage ? <Pagination.Item onClick={() => setPage(previousPage)}>{currentPage}</Pagination.Item> : ""}
            <Pagination.Item active>{currentPage + 1}</Pagination.Item>
            {nextPage ? <Pagination.Item onClick={() => setPage(nextPage)}>{currentPage + 2}</Pagination.Item> : ""}
            {currentPage < totalPages - 3 ? <Pagination.Ellipsis /> : ""}
            {currentPage < totalPages - 2 ? <Pagination.Item onClick={() => setPage(totalPages - 1)}>{totalPages}</Pagination.Item> : ""}
            {nextPage ? <Pagination.Next onClick={() => setPage(nextPage)} /> : ""}
            {nextPage && (currentPage < totalPages - 2) ? <Pagination.Last onClick={() => setPage(totalPages - 1)} /> : ""}
        </Pagination>
    )
}