import React, { useState } from "react";
import { callApi } from "../../components/Request";
import { useAuthContext } from "../../contexts/AuthContext";
import { Helmet } from 'react-helmet';
import { Card, Image, Form, Button, Container } from 'react-bootstrap';
import { FaUser, FaLock } from "react-icons/fa";

function Login() {
	const { userHasAuthenticated } = useAuthContext();
	const [data, setData] = useState({ email: null, password: null })
	const url = '/auth/login'

	const handleChange = (e) => {
		setData({ ...data, [e.target.name]: e.target.value })
	}

	const handleSubmit = (e) => {
		e.preventDefault()
		callApi('post', url, data).then((res) => {
			if (res.token) {
				userHasAuthenticated(true);
				localStorage.setItem("token", res.token)
			}
		})
	}

	return (
		<div className="wrapper">
			<Helmet>
				<link rel="stylesheet" href="../login.css" />
			</Helmet>

			<Card>
				<Form onSubmit={handleSubmit} className="d-flex flex-column">
					<Image fluid="true" src="/logo-circle.png" />
					<Container className="h3 text-center text-white">Login</Container>

					<Form.Label className="d-flex align-items-center input-field my-3 mb-4">
						<FaUser />
						<Form.Control name="email" type="text" onChange={handleChange} placeholder="Username or Email" required className="form-control" />
					</Form.Label>

					<Form.Label className="d-flex align-items-center input-field mb-4">
						<FaLock />
						<Form.Control name="password" onChange={handleChange} type="password" placeholder="Password" required className="form-control" id="pwd" />
					</Form.Label>

					<Container className="my-3">
						<Button type="submit" value="Login" className="btn btn-primary">Login</Button>
					</Container>
				</Form>
			</Card>


		</div>
	)
}

export default Login