import { callApi } from '../../components/Request';
import { useState, useEffect } from 'react'
import { Table, Button } from "react-bootstrap"
import TagsEditorForm from "../../components/forms/TagsEditorForm"
import { AiOutlinePlusCircle } from 'react-icons/ai'
import { RiDeleteBin5Line } from 'react-icons/ri'

function Tags() {
    const url = '/tags/'
    const [tags, setTags] = useState()
    const [newTag, setNewTag] = useState()

    useEffect(() => {
        callApi('get', url).then(res => {
            setTags(res)
        })
    }, [newTag])

    if (!tags) {
        return (
            <div className="container">
                <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        )
    }
    return (
        <>
            <TagsEditorForm setTagState={setNewTag} symbol={<AiOutlinePlusCircle size={25} />} />
            <Table className="mt-2" striped bordered>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Tag</th>
                        <th>ToolBar</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        tags.map((tag) => (
                            <TagElement key={tag.id} tag={tag} triggerRefresh={setNewTag} />
                        ))
                    }
                </tbody>
            </Table>
        </>
    )
}


function TagElement({ tag, triggerRefresh }) {
    const [tagData, setTagData] = useState(tag)
    const url = '/tags/' + tag.id

    const spanStyle = {
        backgroundColor: tagData.tagColor
    }

    const handleClickDelete = () => {
        const del = window.confirm(`Voulez vous vraiment supprimer le tag ${tag.tagName} ?`)
        if (del === true) {
            callApi('DELETE', url).then(() => {
                triggerRefresh(tag.id)
            })
        }
    }

    return (
        <tr>
            <td>{tagData.id}</td>
            <td><span className="tagBadge" style={spanStyle}>{tagData.tagName}</span></td>
            <td className='d-flex gap-2'>
                <TagsEditorForm tag={tagData} setTagState={setTagData} />
                <Button variant='danger' onClick={handleClickDelete}>
                    <RiDeleteBin5Line />
                </Button>
            </td>
        </tr>


    )
}

export default Tags