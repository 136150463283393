import { useParams } from 'react-router'
import { useState, useEffect } from 'react'
import { callApi } from '../../components/Request'
import Editor from 'md-editor-rt';
import 'md-editor-rt/lib/style.css';

function Article() {
    const post = useParams()
    const url = '/articles/' + post.id
    const [data, setData] = useState()

    useEffect(() => {
        callApi('get', url).then(res => {
            setData(res)
        })
    }, [])

    if (!data) {
        return (
            <div className="container">
                <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        )
    }

    return (
        <main className="flex-shrink-0">
            <div className="container">
                <div>
                    <div className="d-flex justify-content-center artTitle">
                        <h3>{data.articleTitle}</h3>
                    </div>
                    <div>
                        <Editor
                            modelValue={data.articleContent}
                            previewOnly={"true"}
                            language={"en-US"}
                            previewTheme={"github"}
                            showCodeRowNumber={true}
                            style={{ "border": "none" }}
                        />
                    </div>
                </div>
            </div>
        </main >
    )
}

export default Article