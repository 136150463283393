import { useEffect, useState } from "react"
import { callApi } from "../Request"
import Editor from 'md-editor-rt';
import Select from 'react-select'
import 'md-editor-rt/lib/style.css';
import { customStyles } from '../styles/ReactMenuCustomStyles'
import Alert from '../Alert'
import { editorToolbar } from '../styles/EditorToolbar'
import axios from "axios";

export default function EditorForm({ initialArticle, initialContent, previousUploads }) {
    // initialize states
    const [article, setArticle] = useState(initialArticle)
    const [content, setContent] = useState(initialContent)
    const [uploadsIds, setUploadsIds] = useState(previousUploads)
    const [alert, setAlert] = useState()
    const [tagList, setTagList] = useState()
    const [categoryList, setCategoryList] = useState()

    useEffect(() => {
        callApi('get', '/tags').then((res) => {
            const optionsTags = []
            res.map((tag) => {
                optionsTags.push({ ...tag, value: tag.id, label: tag.tagName })
            })
            setTagList(optionsTags)
        })
        callApi('get', '/categories').then((res) => {
            const optionsCategories = []
            res.map((category) => {
                optionsCategories.push({ ...category, value: category.id, label: category.categoryName })
            })
            setCategoryList(optionsCategories)
        })
    }, [])

    // Track changes in object
    const handleChange = (e) => {
        setArticle(prevState => ({ ...prevState, [e.target.name]: e.target.value }))
    }

    // MDEditor : Track changes on raw value
    const handleChangeMDEditor = (value) => {
        setContent(value)
    }

    // React-select : Track changes on raw value for Tags
    const handleChangeSelectTags = (value) => {
        setArticle({ ...article, Tags: value })
    }

    // React-select : Track changes on raw value for Category
    const handleChangeSelectCategory = (value) => {
        setArticle({ ...article, Category: value })
    }

    const handleUploadImage = async (files, callback) => {
        const res = await Promise.all(
            Array.from(files).map((file) => {
                return new Promise((rev, rej) => {
                    const form = new FormData();
                    form.append('file', file);
                    axios.post(process.env.REACT_APP_API_URL + '/articles/upload', form, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': localStorage.getItem('token')
                        }
                    })
                        .then((res) => {
                            if (res.data.id) {
                                setUploadsIds(prevState => [...prevState, res.data.id])
                                rev(res)
                            }
                        })
                        .catch((error) => rej(error));
                });
            })
        );
        callback(res.map((item) => {
            return item.data.uploadPath
        }));
    }

    // Submit
    const handleSubmit = (e) => {
        const postUrl = '/articles/'
        e.preventDefault()
        const result = { ...article, articleContent: content, Uploads: uploadsIds }
        callApi((result.id ? 'put' : 'post'), (result.id ? postUrl + result.id : postUrl), result)
            .then((res) => {
                if (res?.data?.id) {
                    // if response contain id, there is new article
                    // let's persist id in article object for the next put request
                    setArticle(prevState => ({ ...prevState, id: res.data.id }))
                }
                if (res?.alert) {
                    setAlert()
                    setAlert(<Alert initalAlert={res.alert} />)
                }
            })
    }

    if (!article && !content && !tagList && !categoryList) {
        return (
            <div className="container">
                <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        )
    }

    return (
        <div className="container">
            {alert && alert}
            <h2 className="h2">Editer un article :</h2>
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-12 col-md mb-3">
                        <label htmlFor="articleTitle" className="form-label">Title</label>
                        <input value={article.articleTitle} onChange={handleChange} name="articleTitle" type="text" className="form-control" />
                    </div>
                    <div className="col d-flex flex-column justify-content-center align-items-center">
                        <div className="form-check form-switch">
                            <label className="form-check-label align-text-middle" htmlFor="articlePublished">Published</label>
                            <input onChange={() => setArticle(prevState => ({ ...prevState, articlePublished: !prevState.articlePublished }))} className="form-check-input" type="checkbox" id="articlePublished" checked={article.articlePublished} />
                        </div>
                    </div>

                </div>
                <div className="row">
                    <div className="col-12 col-md mb-3">
                        <label htmlFor="articleDescription" className="form-label">Description</label>
                        <input value={article.articleDescription} onChange={handleChange} name="articleDescription" type="text" className="form-control" />
                    </div>
                    <div className="col-12 col-md mb-3">
                        <label htmlFor="articleCategory" className="form-label">Category</label>
                        <Select
                            styles={customStyles}
                            defaultValue={article?.Category ? { value: article.articleCategory, label: article.Category.categoryName } : null}
                            options={categoryList}
                            onChange={handleChangeSelectCategory}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <label htmlFor="articleTags" className="form-label">Tags</label>
                        <Select
                            defaultValue={() => {
                                const values = []
                                if (article?.Tags) {
                                    article.Tags.map((tag) => {
                                        values.push({ ...tag, value: tag.id, label: tag.tagName })
                                    })
                                    return values
                                }
                                else {
                                    return null
                                }
                            }}
                            options={tagList}
                            styles={customStyles}
                            onChange={handleChangeSelectTags}
                            className="mb-3"
                            isMulti
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <label htmlFor="articleContent" className="form-label">Content</label>
                        <Editor
                            modelValue={content}
                            onChange={handleChangeMDEditor}
                            language={"en-US"}
                            onSave={handleSubmit}
                            toolbars={editorToolbar}
                            style={{ "height": "800px" }}
                            previewTheme={"github"}
                            showCodeRowNumber={true}
                            onUploadImg={handleUploadImage}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 d-flex justify-content-end">
                        <button type="submit" className="btn btn-primary">{article.id ? "Save" : "Create"}</button>
                    </div>
                </div>
            </form>
        </div>
    )
}