export const customStyles = {
    menu: (provided, state) => ({
        ...provided,
        color: 'var(--font-color)',
        backgroundColor: 'var(--background-color)',
        inputColor: 'var(--background-color)',
        borderColor: 'var(--background-color-alt3)',
        color: state.selectProps.menuColor,
    }),

    control: (provided, state) => ({
        ...provided,
        color: 'var(--font-color)',
        backgroundColor: 'var(--background-color)',
        inputColor: 'var(--background-color)',
        borderColor: 'var(--background-color-alt3)',
    }),

    option: (provided, state) => ({
        ...provided,
        color: 'var(--font-color)',
        backgroundColor: state.isSelected ? 'var(--background-color)' : 'var(--background-color-alt2)',
        inputColor: 'var(--background-color)',
        borderColor: 'var(--background-color-alt3)',
    }),

    singleValue: (provided, state) => ({
        ...provided,
        color: 'var(--font-color)',
    }),

    multiValue: (provided, state) => ({
        ...provided,
        color: 'var(--font-color)',
        backgroundColor: state.isSelected ? 'var(--background-color)' : 'var(--background-color-alt2)',
        inputColor: 'var(--background-color)',
        borderColor: 'var(--background-color-alt3)',
    }),

    multiValueLabel: (provided, state) => ({
        ...provided,
        color: 'var(--font-color)',
    })
}