export const editorToolbar = [
    'save',
    '-',
    'bold',
    'underline',
    'italic',
    'strikeThrough',
    '-',
    'sub',
    'sup',
    '-',
    'quote',
    'unorderedList',
    'orderedList',
    '-',
    'codeRow',
    'code',
    '-',
    'link',
    'image',
    'table',
    '-',
    'mermaid',
    'katex',
    '-',
    'revoke',
    'next',
    '=',
    'pageFullscreen',
    'fullscreen',
    'preview',
    'htmlPreview',
    'catalog'
];